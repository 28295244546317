<template>
  <div>
    <navbar :title="parent.title" route='/'/>
    <error-alert/>
    <v-main class="pt-15 px-2 pb-15">
      <subordinate :slides='categories' class="mt-2"/>
      <v-card class="d-flex card flat-card pa-2 mt-3 mb-6" color="surface">
        <v-row>
          <v-col class="d-flex align-center">
            <div @click="sort = !sort" class="d-flex align-center">
              <v-icon class="primaryText--text mr-2">mdi-filter-variant</v-icon>
              <span class="medium-font onSurface--text mr-1">براساس</span>
            </div>
          </v-col>
          <v-col class="d-flex align-center justify-end">
            <v-switch dense hide-details class="mb-0 mt-0" v-model="unavailable" label="کالاهای ناموجود"></v-switch>
          </v-col>
        </v-row>
      </v-card>
      <div class="mt-2">
        <div v-for="result_product in results" :key="result_product.id">
        <result v-if="result_product.onhand != 0 && unavailable == false" :result_product = 'result_product'/>
        <result v-if="unavailable == true" :result_product = 'result_product'/> 
        </div>
      </div>
      <infinite-loading v-if="
          results.length > 1 &&
          count_result > 12 &&
          count_result != results.length
        " spinner="waveDots" @infinite="infiniteHandler"
        >
        <span slot="no-results"></span>
        </infinite-loading>
    </v-main>
    <div>
      <actionBar/>
    </div>
    <div>
      <sort :sort = 'sort'/>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { categories_list } from '../categories/models/categories'
import { search_product } from '../search/models/search'
import InfiniteLoading from 'vue-infinite-loading'
import { viewbasket_products } from '../Basket/models/Basket'
import store from '@/store'
import ErrorAlert from '@/components/alerts/error.alert.vue'
export default {
  data: () => ({
    unavailable : false,
    sort: false,
  }),
  components:{
    navbar: () => import ('@/components/navbar/appBar'),
    result: () => import ('../search/components/searchResultCard'),
    subordinate: () => import ('../home/components/slider/subordinateSlider'),
    actionBar: () => import ('./components/navbar/actionBar'),
    sort: () => import('@/views/shop/search/components/sort'),
    ErrorAlert,
    InfiniteLoading
  },
  computed: {
    ...mapState({
      categories: state => state.categories.categories,
      results: state => state.search.result,
      count_result: state => state.search.count_result,
      loading: state => state.search.load_page,
    }),
    parent () {
      const parent = JSON.parse(localStorage.getItem('category_id'))
      return parent
    }
  },
  created () {
    const basket = localStorage.getItem('basket')
        if (basket) {
          const time = new Date()
          const now = time.getTime()
          const old_time_basket = parseInt(localStorage.getItem('time_basket'))
          if ((old_time_basket + 86400000) < now ) {
            localStorage.removeItem('basket')
            viewbasket_products()
          } else {
          store.dispatch('basket_local', JSON.parse(basket))
          }
        } else {
          viewbasket_products()
        }
    localStorage.removeItem('filter_search')
    const parent = JSON.parse(localStorage.getItem('category_id'))
    if (this.results.length < 1) {
      categories_list(parent.id)
    }
  },
  mounted () {
    if (this.results.length > 1) {
      return
    }
    let filters = localStorage.getItem('filter_search')
    const category = JSON.parse(localStorage.getItem('category_id'))
        if (filters) {
          filters = JSON.parse(filters)
          filters.page = 0
          let tages = category.tag[0]
          if (category.tag.length > 1) {
            for (const key of category.tag) {
              tages = key + ',' + tages
            }
          }
          filters.tags = tages
          localStorage.setItem('filter_search', JSON.stringify(filters))
          filters.page = 1
          search_product(filters)
        } else {
          filters = {}
          filters.page = 0
          let tages = category.tag[0]
          if (category.tag.length > 1) {
            for (const key of category.tag) {
              tages = tages + ',' + key
            }
          }
          filters.tags = tages
          localStorage.setItem('filter_search', JSON.stringify(filters))
          filters.page = 1
          search_product(filters)
        }
  },
  methods: {
    infiniteHandler ($state) {
        let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
        }
       if (filters.page != 0) {
          search_product(filters).then(({ data }) => {
        if (data.Result.length) {
          filters.page = parseInt(filters.page) + 1
          localStorage.setItem('filter_search', JSON.stringify(filters))
          $state.loaded();
        } else {
          $state.complete();
        }
      })
      } else{
         filters.page = parseInt(filters.page) + 1
         localStorage.setItem('filter_search', JSON.stringify(filters))
         $state.loaded();
      }
    },
  }
}
</script>